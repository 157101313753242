import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { TokenResponse } from '@app/shared/models/auth/TokenResponse';
import { LoginResponse } from '@app/shared/models/auth/LoginResponse';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) {}

    login(email: string, password: string): Observable<LoginResponse> {
        return this.http
            .post<TokenResponse>(
                this.baseUrl + '/signin',
                { email, password },
                { withCredentials: true }
            )
            .pipe(
                tap((data) => localStorage.setItem('token', data.token)),
                map(() => {
                    const redirectUrl =
                        sessionStorage.getItem('redirectUrl') || '/';
                    sessionStorage.removeItem('redirectUrl');
                    return { redirect: redirectUrl };
                }),
                catchError((error) => {
                    // Handle error
                    console.error('Login failed:', error);
                    return of({ redirect: '/login' });
                })
            );
    }

    logout(): Observable<void> {
        return this.http
            .post<void>(
                this.baseUrl + '/token/invalidate',
                {},
                { withCredentials: true }
            )
            .pipe(
                tap(() => localStorage.removeItem('token')),
                catchError((error) => {
                    // Handle error
                    console.error('Logout failed:', error);
                    return of();
                })
            );
    }
}
